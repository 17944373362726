









































import { ISite } from '@/store/modules/sites/types'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { IDataLoaderData, ILoader } from './types'
import { IRfSensor } from '@/store/modules/rf_sensors/types'

const props = {
  showPercentage: {
    type: Boolean,
    default: true
  }
}

export default Vue.extend({
  name: 'DataLoader',
  props,
  data(): IDataLoaderData {
    return {
      currentLoader: null,
      loaderCount: 0
    }
  },
  async mounted(): Promise<void> {
    for (const loader of this.allLoaders) {
      this.currentLoader = loader
      await loader.callback()
      this.loaderCount += 1
    }
  },
  computed: {
    ...mapGetters('system', ['assetsPath', 'useBranding']),
    ...mapState('users', ['user']),
    ...mapState(['timezonesList']),
    currentProgress(): number {
      if (this.loaderCount === 0) return 0
      return Math.floor((this.loaderCount / this.allLoaders.length) * 100)
    },
    logoImg(): string {
      return require(`@/assets/${this.assetsPath}logo.svg`)
    },
    allLoaders(): ILoader[] {
      return [
        ...this.loaders,
        {
          id: 'complete',
          label: 'Complete',
          callback: () => {
            setTimeout(() => {
              this.$emit('complete')
            }, 750)
          }
        }
      ]
    },
    loaders(): ILoader[] {
      return [
        {
          id: 'user_profile',
          label: 'User Profile',
          callback: this.setupUser
        },
        {
          id: 'site_plans',
          label: 'User Plans',
          callback: this.fetchSitePlans
        },
        {
          id: 'map_tiler_online_key',
          label: 'MapTiler Online Key',
          callback: this.fetchMapTilerKey
        },
        {
          id: 'init_map_layers',
          label: 'Map Layers',
          callback: this.initializeActiveMapLayers
        }
      ]
    }
  },
  methods: {
    ...mapActions('users', [
      'FETCH_USER',
    ]),
    ...mapActions('plans', ['FETCH_PLANS']),
    ...mapActions('maps', ['setActiveMapLayers', 'setMapLayer', 'getMapTilerOnlineKey']),
    ...mapActions('system', ['setDataLoadedState']),
    async setupUser(): Promise<void> {
      if (Object.keys(this.user).length === 0) {
        await this.FETCH_USER()
      }
    },
    async fetchSitePlans(): Promise<void> {
      await this.FETCH_PLANS()
    },
    async fetchMapTilerKey(): Promise<void> {
      await this.getMapTilerOnlineKey()
    },
    async initializeActiveMapLayers(): Promise<void> {
      const mapLayerKey = this.user?.settings?.mapLayerKey
      await this.setActiveMapLayers(mapLayerKey)
    }
  }
})
