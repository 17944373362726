




import Vue from 'vue'
import DataLoader from '../components/DataLoader/DataLoader.vue'
import { mapActions, mapState } from 'vuex'

const props = {
  id: {
    type: [Number, String],
    default: null
  }
}

export default Vue.extend({
  name: 'DataLoaderView',
  props,
  components: { DataLoader },
  data() {
    return {
      ready: false
    }
  },
  methods: {
    ...mapActions('system', ['setDataLoadedState']),
    onComplete(): void {
      // does this need to depend on successful socket subscriptions?
      this.setDataLoadedState(true)
      this.$router.replace({ name: 'Home' })
    },
  }
})
